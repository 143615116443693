import './sticky_footer_cta.scss';

import { create_element } from 'src/js/utils';

// mode = "on_scroll" or "on_load"
// const show_mode = 'on_load';
const show_mode = 'on_scroll';

// for on_scroll mode, scroll distance when it will show
const activate_on_scroll_distance = 700; // 700 px down the page

// for on_load mode, delay after page loaded when it will show
const activate_on_load_delay = 4000; // after 4 seconds

const container = document.querySelector('.sticky_footer_cta');
const footer = document.querySelector('.elementor-location-footer');
let is_showing = false;

function init(){
    if( !container ) return;

    if( getCookie('footer_sticky_cta_close') ) {
        container.remove();
        // container.style.display = 'none';
        return;
    }
    
    initial_styles();
    close_button_init();

    if( show_mode == 'on_scroll' ) {
        on_scroll_mode_init();
    }
    else if ( show_mode == 'on_load' ) {
        on_load_mode_init();
    }
}
document.addEventListener('DOMContentLoaded', init);

function initial_styles(){
    
    container.style.display = 'none';
    container.style.position = 'fixed';
    container.style.transform = 'translateY(100%)';

    setTimeout(()=>{
        container.style.display = '';
        // add padding to footer
        footer.style.paddingBottom = container.offsetHeight+'px';
    }, 100);
}

function close_button_init(){
    let close_button = create_element({ class_name: 'close_button icon-z-icox', render: container });
    container.close_button = close_button;
    close_button.addEventListener('click', close);
}

function close(){
    setCookie('footer_sticky_cta_close', 1, 24);
    hide();
    document.removeEventListener('scroll', scroll_handler);
    footer.style.paddingBottom = '';
}

function show(){
    if( is_showing ) return;
    is_showing = true;
    container.classList.add('showing');
    container.style.transform = 'translateY(0)';
}

function hide(){
    if( !is_showing ) return;
    is_showing = false;
    container.classList.remove('showing');
    container.style.transform = 'translateY(100%)';
}

function on_scroll_mode_init(){
    document.addEventListener('scroll', scroll_handler);
}

function on_load_mode_init(){
    setTimeout(show, activate_on_load_delay);
}

function scroll_handler(){
    if( window.scrollY >= activate_on_scroll_distance ) {
        show();
    }
    else {
        hide();
    }
}